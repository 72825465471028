import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorInteractionsTranslations,
  IExhibitorSettingsTranslations,
  IExhibitorSidebar,
  IExhibitorsDashboardTranslations,
  IExhibitorProducsTranslations,
  IExhibitorProductInteractions,
  IExhibitorProfile,
  IExhibitorTickets,
  IExhibitorVisitors,
  IExhibitorExports,
  IExhibitorTeam,
  IExhibitorsBookletTranslations,
} from '.';

const booklet: IExhibitorsBookletTranslations = {
  cta_copy: 'Ejemplo de libro de visitas',
  success_message:
    'Se le enviará a su dirección de correo electrónico un libro de visitas con todos los expositores.',
  no_booklet_message: 'No hay ningún libro de visitas que enviar.',
  modal_title: 'Ejemplo de libro de visitas',
  modal_subtitle: 'Se enviará un ejemplo de libro de visitas a',
  send_booklet: 'Enviar',
};

const dashboard: IExhibitorsDashboardTranslations = {
  title: 'Hola',
  subtitle: 'Bienvenido a la aplicación de gestión de eventos beamian',
  ticket_codes_title: 'Códigos de las entradas',
  ticket_codes_subtitle: '¡Copia y envía estos códigos a quien quieras invitar!',
  usable_tickets: 'Entradas no utilizadas',
  general_information_title: 'Tus indicadores de rendimiento',
  general_information_subtitle: 'Cómo interactúan los candidatos contigo',
  sessions_subtitle: 'Quién se inscribió en tus sesiones',
  sessions_title: 'Tus números de registro',
  booked_as_favorite: 'Marcaciones como favorito',
  upcoming_sessions: 'Siguientes sesiones',
  exhibitor_pending_title: 'Su empresa no ha sido aprobada',
  exhibitor_pending_text: 'Por favor, espere hasta que el gestor del evento apruebe su empresa',
};

const exports: IExhibitorExports = {
  title: 'Exportaciones',
  subtitle: 'Administra y descarga tus exportaciones de contactos',
  export_contacts: 'Exportar contactos',
};

const team: IExhibitorTeam = {
  title: 'Equipo',
  subtitle: 'Gestionar a los demás gestores de expositor',
  title_empty: '¡Aquí aparecerán los gestores de su expositor!',
  subtitle_empty: 'Invite a más gestores de expositor.',
  tab_1: 'Gestores de expositor',
  tab_2: 'Invitaciones pendientes',
  exhibitor_mnanagers_left: 'gestores de expositores.',
  resend_registration_email: 'reenviar el correo electrónico de registro',
  resend_invitation: 'Reenviar la invitación',
  confirm_removing_exhibitor_manager:
    '¿Desea proceder a la eliminación de este gestor de expositor?',
  confirm_removing_exhibitor_manager_invite:
    '¿Desea proceder a la eliminación de esta invitación para un gestor de expositor?',
  email_input_title: 'Correo electrónico',
  email_input_placeholder: 'johndoe@gmail.com',
  invite_exists: 'Este contacto ya ha sido invitado.',
};

const visitors: IExhibitorVisitors = {
  title: 'Visitantes',
  subtitle: 'Ver información del visitante, invitar a sesiones privadas',
  assign_packages: 'Asignar paquetes',
  visitor_information: 'Información del visitante',
  assign_step_1: 'Encuentra un visitante existente o registra uno nuevo',
  assign_step_2: 'Registrar nuevo visitante',
  assign_step_3: 'Agregar paquetes al visitante',
  assign_step_4: 'Todo listo!',
  assign_step_5: 'No se pueden asignar más paquetes',
  out_of_packages: 'Te quedaste sin paquetes para asignar.',
  package_name: 'Nombre del paquete',
  package_assigned_successfuly: 'Paquetes asignados correctamente!',
  assign_another: 'Asignar de nuevo',
  invite_private_session: 'Invitar a una sesión privada',
  user_not_registered: 'Este visitante no está registrado para ninguna sesión con su expositor.',
  enter_note_placeholder: 'Introduzca su nota',
  empty_list_title: '¡Tus contactos aparecerán aquí!',
  empty_list_subtitle: '¡Comparte tu página de expositor para conseguir más interacciones!',
  filters: {
    registered_for_sessions: 'Registrado en las sesiones',
    interacted_with_exhibitor: 'Interactuó con el expositor',
    interacted_with_product: 'Interactuó con el producto',
    invited_by_you: 'Invitado por usted',
    redeemed_code: 'Canjeó uno de sus códigos',
  },
  sessions: {
    export: 'Exportar',
    export_sessions: 'Exportar sesiones',
    message_201:
      'Se ha iniciado la creación del archivo de exportación. Por favor, inténtelo de nuevo en un momento',
    message_204:
      'Se está creando el archivo de exportación. Por favor, inténtelo de nuevo en un momento',
    subtitle: 'Seleccione información adicional del visitante para incluir en la exportación:',
  },
};

const tickets: IExhibitorTickets = {
  title: 'Tareas',
  subtitle: 'Bilhetes atribuidos a visitantes',
  new_assignment: 'Nova atribuição',
  assignee_name: 'Nome do recebedor/a',
  assignee_email: 'Email do recebedor/a',
  visitor_name: 'Nome do visitante',
  visitor_email: 'Email do visitante',
  ticket: 'Bilhete',
  ticket_type_title: 'Bilhetes',
  ticket_type_subtitle: 'Atribua bilhetes a visitantes',
  assign_title: 'Assign ticket to a visitor',
  assign: 'Assign',
  ticket_success: 'Ticket assigned successfuly!',
};

const sidebar: IExhibitorSidebar = {
  title: 'Exhibiendo',
  home: 'Home',
  interactions: 'Visitantes',
  exhibitor_interactions: 'Interesados en tu empresa',
  exhibitor_sessions: 'Sesiones de empresa',
  settings: 'Formulario',
  sessions: 'Sesiones',
  products: 'Productos',
  product_interactions: 'interesados en ofertas',
  tickets: 'Tickets',
  exports: 'Exportaciones',
  team: 'Equipo',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Configuración del expositor',
  basicInfo: 'Información básica',
  save: 'Guardar',
  additionalInfo: 'Información adicional',
  formError: 'Información no recuperada, formulario no disponible.',
};

const products: IExhibitorProducsTranslations = {
  title: 'Productos',
  subtitle: 'Crear, editar y eliminar productos',
  no_products: 'No hay productos disponibles',
  new_product: 'Nuevo producto',
  create_new_product: 'Crear un producto',
  edit_product: 'Editar producto',
  delete_confirm: 'Está seguro de que desea eliminar este producto?',
  interactions_exist_cannot_delete:
    'Este producto ya tiene interacciones, por lo que no es posible eliminar',
  inputs: { name: 'Nombre' },
  search_placeholder: 'Escriba lo que desea buscar en la lista de productos',
  you_have: 'Te quedan',
  products_left: 'productos.',
  form_not_ready:
    'El formulario del producto aún no ha sido creado por su gestor de eventos. Por favor, inténtelo de nuevo más tarde.',
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Visitantes interesados en su empresa',
  subtitle: 'Mira la lista de visitantes interesados en tu empresa',
  download: 'Descargar',
  noInteractions: 'Este expositor no tiene interacciones.',
  interaction: {
    editComment: 'Editar comentario',
    saveComment: 'Guardar comentario',
    checkIn: 'Check-in',
  },
  search_placeholder: 'Escriba lo que desea buscar en la lista de solicitudes de contactos',
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Autenticación',
  step_basicInfo: 'Información básica',
  step_additionalInfo: 'Información adicional',
  step_review: 'Revisión',
  step_done: 'Hecho!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Imagen de perfil',
    embedded: 'Video de Youtube',
    meeting_room: 'Activar sala de reuniones virtual',
  },

  login: {
    title: 'Estás a punto de registrarte',
    subtitle: 'Primero haz login o regístrate',
    message: 'Para registrarte, necesitamos que primero hagas login o te registres.',
    exhibitor_manager_name: 'Inserta el nombre del gerente del stand',
    exhibitor_manager_email: 'Inserta el email del gerente del stand',
  },
  basicInformation: {
    title: 'Dinos más sobre tu organización',
    warning:
      'La información que has insertado será utilizada para la creación del booklet del evento, así como para crear un perfil que será visto por los demás participantes.',
    createExhibitor: 'Crear',
    exhibitor_name: 'Nombre de la organización',
    exhibitor_name_placeholder: 'Insertar nombre de la organización',
    modal: {
      title: 'Aviso',
      subtitle: 'Si quieres gestionar tu expositor, por favor, haz login primero.',
      skip: 'Saltar',
      createAccount: 'Crear cuenta',
      login: 'Login',
    },
  },
  managedFields: {
    title: 'Información adicional',
    crop_image: 'Cortar imagen',
    crop_again: 'Volver a cortar',
  },
  review: {
    title: 'Revisar información',
    name: 'Nombre',
  },
  completed: {
    title: 'Perfil creado!',
    subtitle: 'Tu perfil está listo. Gracias por completar la información!',
    manage: 'Gestionar',
  },
  back: 'Atrás',
  next: 'Adelante',
  submit: 'Enviar',
};

const sessions: IExhibitorSessions = {
  title: 'Sesiones',
  subtitle: 'Crear, editar y eliminar reuniones',
  start_date: 'Empieza a:',
  end_date: 'Termina en:',
  join_meeting: 'Unirse a la sesión',
  meeting_url: 'URL de la reunión:',
  description: 'Descripción:',
  new_session: 'Nueva sesión',
  new_spacer: 'Nueva pestaña',
  edit_session: 'Editar Sesión',
  edit_spacer: 'Editar pestaña',
  register: 'Registrarse en esta sesión',
  invited_users: 'Convidados',
  inputs: {
    name: 'Nombre',
    name_placeholder: 'Escriba el nombre de la sesión',
    start_date: 'Día y hora de inicio',
    start_date_placeholder: 'Escriba la día y hora de inicio de la sesión',
    end_date: 'Día y hora de finalización',
    end_date_placeholder: 'Escriba la día y hora de inicio de la sesión',
    color: 'Color de la sesión',
    color_placeholder: 'Elija el color de la sesión',
    widget_block: 'Bloque de widget',
    widget_block_placeholder: 'Introduzca el código html',
    widget_block_message:
      'Este texto o html aparecerá en la página de la sesión debajo del vídeo o imagen principal y encima de la descripción.',
    description: 'Descripción',
    description_placeholder: 'Escriba la descripción de la sesión',
    entrance_capacity: 'Capacidad de entrada',
    entrance_capacity_placeholder: 'Ingrese la capacidad del espacio',
    registration_capacity: 'Capacidad de registro',
    registration_capacity_placeholder: 'Ingrese la capacidad de registro',
    external_link: 'URL de la reunión',
    external_link_placeholder: 'Escriba la URL de la reunión',
    external_link_warning_message: 'Dejar vacío si quieres que beamian cree una sala de reuniones',
    order: 'Orden',
    order_placeholder: 'Elija el orden de la sesión',
    private_session: 'Sesión privada',
    streaming_link: 'URL de transmisión',
    streaming_link_placeholder: 'Ingresar URL de transmisión',
    streaming_link_warning_message:
      'Esta transmisión estará disponible para todos los usuarios, incluidos los que no están registrados para esta sesión.',
    image: 'Imagen',
    image_placeholder: 'Sube la imagen de tu sesión',
    hide: 'Esconder',
    hide_on_calendar: 'Esconderse en el calendario',
    hide_remaining_capacity: 'Ocultar la capacidad restante',
    allow_exited_users_entering: 'Los visitantes pueden reingresar',
    is_daily_checkin: 'Work as a daily checkin session',
    is_daily_checkin_help_text:
      'This session will be available for in-person access control in a specific date',
    use_default_language_only: 'Utilizar el idioma del evento por defecto',
    display_counter: 'Mostrar un contador con el número de usuarios viendo el stream',
    display_counter_placeholder: 'Sólo aplicable a los streams de Amazon IVS.',
    location_image_placeholder: 'Sube la imagen de tu localización',
  },
  session_label: 'Sesión',
  select_session: 'Seleccione la sesión',
  no_private_sessions: 'No hay sesiones privadas',
  private_session_warning:
    'Si la sesión es privada, sólo la verán los participantes invitados. Si es una sesión pública, será visible para todos los participantes.',
  enable_mod_chat: 'Habilitar chat de moderación',
  enable_chat: 'Habilitar chat',
  embed_meeting: 'Sesión de interna',
  embed_meeting_detail:
    'Si desactiva esta opción, la URL de la sesión se abrirá en una nueva pestaña',
  you_have: 'Te quedan',
  sessions_left: 'sesiones',
  new_stage: 'Nueva Etapa',
  add_stage: 'Añadir Etapa',
  managers: 'Gestores',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'interesados en ofertas',
  subtitle:
    'Mira la lista de visitantes interesados en tus productos, chatea con ellos o envíales una reunión',
  product_name: 'Producto',
  all_products: 'Todas las ofertas de trabajo',
  interaction_name: 'Nombre del visitante',
  application: 'Información del visitante',
  invite_to_session: 'Invitar a una sesión privada',
  send_invite: 'Enviar invitación',
  user_already_registered: 'Este usuario ya está registrado en esta sesión',
  publish_warning: 'Al publicar la oferta de empleo, será visible para los visitantes',
  invitation_sent: 'Invitación enviada con éxito',
  search_placeholder: 'Escriba lo que desea buscar en la lista de solicitudes de información',
  empty_list_title: 'Sus interacciones con el producto se mostrarán aquí',
  empty_list_subtitle: '¡Comparte tu página de expositor para conseguir más interacciones!',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Información de la empresa',
  products: 'Ofertas de trabajo',
};

export default {
  booklet,
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  team,
  tickets,
};
