import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorExhibitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Perfil',
  networking: 'Networking',
  networking_description: 'Falar com outros visitantes',
  sessions: 'Agenda',
  sessions_description: 'Número de sessões na agenda do evento:',
  sessions_btn: 'Ver agenda do evento',
  name: 'Nome de visitante:',
  edit: 'Alterar',
  exhibitors: 'Expositores',
  nrOfInteractions: 'Número de contactos partilhados:',
  seeAll: 'Ver expositores',
  download: 'Descarregar',
  join_networking: 'Entrar no espaço de networking',
  event_finished: 'Este evento já terminou',
  event_not_start: 'Este evento ainda não começou',
  featured_products: 'Ofertas em destaque',
  featured_sessions: 'Sessões em destaque',
  featured_exhibitors: 'Expositores em destaque',
  upcoming_exhibitors: 'Próximas sessões',
};

const sidebar: IVisitorSidebar = {
  title: 'Visitante',
  home: 'Home',
  exhibitors: 'Expositores',
  settings: 'Perfil',
  sessions: 'Agenda',
  exhibitor_sessions: 'Agenda pessoal',
  event_sessions: 'Agenda do evento',
  myCalendar: 'Agenda pessoal',
  eventCalendar: 'Agenda do evento',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Formulário de visitante',
  basicInfo: 'Informação básica',
  save: 'Guardar',
  additionalInfo: 'Informação de registo',
  formError: 'Sem informação para mostrar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Expositores',
  subtitle: 'Ver expositores e consultar ofertas',
  download: 'Descarregar',
  noExhibitors: 'Sem expositores para mostrar.',
  exhibitor_information: 'Informação de expositor',
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  all_exhibitors: 'Todos os expositores',
  interacted_exhibitors: 'Expositores com que interagi',
  not_interacted_exhibitors: 'Expositores com que não interagi',
  filter_exhibitors: 'Filtrar expositores',
  contact_request: 'Partilhar contactos',
  company_info: 'Informação do expositor',
  job_offers: 'Ofertas',
  apply_to_job_offer: 'Registar interesse',
  job_offer_info: 'Informação da oferta',
  cover_letter: 'Motivo do interesse',
  cover_letter_text: 'Deixe uma mensagem ao expositor',
  your_info_will_be_shared: 'A sua informação de registo será partilhada com o expositor',
  submit_my_application: 'Submeter',
  successfully_submited: 'Efetuado com sucesso',
  already_applied: 'Já efetuou este registo de interesse',
  schedule_interview: 'Reserve o seu lugar',
  select_time_slot: 'Selecione um horário para participar',
  all_visitors: 'Todos os visitantes',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  basicInformation: {
    title: 'Dados de visitante',
    createVisitor: 'Registar como visitante',
    modal: {
      title: 'Aviso',
      subtitle: 'Se quiser aceder ao evento após o registo terá que iniciar sessão primeiro.',
      skip: 'Saltar',
      createAccount: 'Criar conta',
      login: 'Iniciar sessão',
    },
    already_registered: 'Já está registado neste evento!',
    send_login_link: 'Clique aqui para receber um email com link de acesso ao evento.',
  },
  managedFields: {
    title: 'Informação de registo',
  },
  tickets: {
    title: 'Bilhetes',
    subtitle: 'Comprar bilhetes ou utilizar um código de bilhete',
    message_head: 'Se precisar de comprar muitos bilhetes e registar-se mais tarde, por favor',
    to_buy_tickets: 'para comprar bilhetes;',
    copy_ticket_code: 'Copie o código que lhe enviaremos para o email e use-o para se registar.',
    ticket_code_invalid: 'O código do bilhete é inválido ou já foi usado',
    ticket_code_label: 'Código de registo',
    ticket_code_placeholder: 'Insira o seu código de bilhete',
    use_ticket_code: 'Use um código de bilhete',
  },
  review: {
    title: 'Rever informação',
    name: 'Nome',
    proceed_to_payment: 'Proceder ao pagamento',
    register_someone_else: 'Registar outra pessoa',
  },
  checkout: {
    vat_label: 'Número de contribuinte',
    country_label: 'Country',
    country_default: 'Seleccione o seu país',
    city_label: 'Cidade',
    address_label: 'Address',
    zip_code: 'Zip code',
    payment_method: 'Método de pagamento',
    payment_method_card_label: 'Cartão de crédito ou de débito',
    payment_processed: 'Pagamento processado',
    check_your_phone: 'Por favor, verifique o seu telefone',
    payment_failed: 'Pagamento falhado. Por favor, tente novamente',
    payment_details_sent: 'Enviámos os detalhes do pagamento para o seu e-mail.',
    mbref_payment_message: 'Enviaremos uma referência MB para o seu correio electrónico',
  },
  completed: {
    title: 'Obrigado pela sua inscrição!',
    subtitle: 'Por favor verifique a sua caixa de entrada, enviamos-lhe um e-mail de confirmação.',
    manage: 'Gerir',
  },
  back: 'Voltar',
  next: 'Próximo',
  submit: 'Submeter',
  not_allowed_title: 'A sua conta não foi aprovada',
  not_allowed_subtitle: 'Por favor aguarde pela aprovação do gestor de evento',
  registration_slow: 'O registo está a demorar mais que o habitual. Por favor, aguarde.',
  order_summary: 'Resumo da encomenda',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Fale com outros visitantes',
  chat: 'Chat',
  profile: 'Perfil',
  signup_title: 'Pretende falar com outros visitantes?',
  signup_message:
    'Está prestes a entrar na área de networking. A sua informação ficará disponível para outros visitantes.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Consulte aqui as suas inscrições nas sessões do evento',
  anonymous_subtitle: 'Consulte aqui e registe-se nas sessões do evento',
  event_sessions: 'Sessões do evento',
  exhibitor_sessions: 'Sessões de expositores',
  all_sessions: 'Todas as sessões',
  join_in_15: 'Esta sessão não está a decorrer',
  need_help: 'Precisa de ajuda?',
  refresh: 'Refresh',
  troubleshoot: 'Resolução de problemas',
  help_title: 'Tem problemas de som ou video?',
  help_content: `
    <p>
      Se tiver problemas com chat ou áudio, certifique-se de que não está a usar um proxy restrito
      ligação ou ter uma VPN activada neste momento. <br />
    </p>
    <p>
    Verifique
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >este guia</a
      > para ajudar a resolver qualquer problema não resolvido através da actualização das definições da sua rede.
    </p>
    <p>
     As seguintes portas de rede devem estar abertas na sua firewall para permitir o tráfego do servidor:
    </p>
    <ul>
     <li>80 TCP – para a verificação/renovação de certificados SSL com Let’s Encrypt.</li>
     <li>443 TCP – para acesso geral ao software de videochamada;</li>
     <li>10000 UDP - para comunicações gerais de vídeo/áudio em rede.</li>
     <li>TCP/4443</li>
    </ul>
  `,
  select_stage: 'Selecione um palco',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessões em que está inscrito/a',
};

const profile: IVisitorProfile = {
  visitor_information: 'Informação do visitante',
};

const products: IVisitorProducts = {
  title: 'Ofertas',
  subtitle: 'Procurar e interagir com ofertas de expositor',
  products_same_brand: 'Ofertas do mesmo expositor',
  select_category: 'Selecione uma categoria',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
};
