import {
  IVisitorOnboardingTranslations,
  IVisitorSessions,
  IVisitorExhibitorTranslations,
  IVisitorSettingsTranslations,
  IVisitorSidebar,
  IVisitorsDashboardTranslations,
  IVisitorNetworkingTranslations,
  IVisitorExhibitorSessions,
  IVisitorProfile,
  IVisitorProducts,
  IVisitorStore,
  IVisitorChatMessages,
} from './index';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Profile',
  networking: 'Networking',
  networking_description: 'Meet fellow visitors',
  sessions: 'Calendar',
  sessions_description: 'See all sessions',
  sessions_btn: 'Event calendar',
  name: 'Name of the visitor:',
  edit: 'Edit',
  exhibitors: 'Exhibitors',
  nrOfInteractions: 'See all exhibitors',
  seeAll: 'Exhibitors',
  download: 'Download',
  join_networking: 'Networking',
  event_finished: 'Event is finished',
  event_not_start: 'Event did not start yet',
  featured_products: 'Featured offers',
  featured_sessions: 'Featured sessions',
  featured_exhibitors: 'Companies',
  upcoming_exhibitors: 'Next sessions',
};

const sidebar: IVisitorSidebar = {
  title: 'Visiting',
  home: 'Home',
  exhibitors: 'Exhibitors',
  settings: 'Profile',
  sessions: 'Calendar',
  exhibitor_sessions: 'My calendar',
  event_sessions: 'Event calendar',
  myCalendar: 'My calendar',
  eventCalendar: 'Event calendar',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Visitor form',
  basicInfo: 'Basic information',
  save: 'Save',
  additionalInfo: 'Registration information',
  formError: 'No information retrieved, form not available.',
};

const store: IVisitorStore = {
  title: 'Store',
  subtitle: 'Add additional sessions and/or items',
  add_to_cart: 'Add to cart',
  other_addons: 'Other addons',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Exhibitors',
  subtitle: 'See exhibitors and check their offers',
  download: 'Download',
  noExhibitors: 'No exhibitors to show.',
  exhibitor_information: 'Exhibitor information',
  contact_success: 'Your information was shared succesfuly!',
  all_exhibitors: 'All exhibitors',
  interacted_exhibitors: 'Exhibitors I interacted with',
  not_interacted_exhibitors: 'Exhibitors I didn’t interact with',
  filter_exhibitors: 'Filter exhibitors',
  contact_request: 'Leave my contact',
  company_info: 'Exhibitor information',
  job_offers: 'Offers',
  apply_to_job_offer: "I'm interested",
  job_offer_info: 'Information about the offer',
  cover_letter: 'Why are you interested?',
  cover_letter_text:
    'Let the exhibitor know why you’re interested and/or ask him a question about the offer',
  your_info_will_be_shared: 'Your information will be shared with the exhibitor',
  submit_my_application: 'Submit',
  successfully_submited: 'Successfully submitted ',
  already_applied: 'You have already shared your interest in this offers.',
  schedule_interview: 'Reserve your spot',
  select_time_slot: 'Choose a time slot to join',
  all_visitors: 'All visitors',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Visitor details',
    createVisitor: 'Register as a visitor',
    select_type: 'Select registration type',
    modal: {
      title: 'Warning',
      subtitle: 'If you wish to manage your visitor, please login first.',
      skip: 'Skip',
      createAccount: 'Create account',
      login: 'Login',
    },
    already_registered: 'You are already registered in this event!',
    already_registered_pending: 'There already is a pending registration for you in this event!',
    already_registered_awaiting_payment:
      "There already is a registration for you in this event that's awaiting a payment to be completed!",
    pre_registered_pending: 'You have been pre-registered for this event.',
    send_payment_details: 'Click here to receive an email with the payment details.',
    send_login_link: 'Click here to receive the confirmation email.',
    send_rsvp_link: 'Click here to receive an email with the link to activate your registration.',
    no_payment_info: 'Could not find the information on this payment.',
    no_pre_registration: 'The information on this pre-registration could not be found.',
    payment_info_sent: 'Check your inbox, we sent you information to complete your payment!',
    rsvp_link_sent: 'Check your inbox, we sent you information to activate your registration!',
    pre_registered_email_message:
      'The email cannot be changed until the registration is activated.',
  },
  tickets: {
    title: 'Tickets',
    subtitle: 'Buy tickets or redeem your ticket code',
    subtitle_store: 'Buy tickets',
    message_head: 'If you need to buy tickets in bulk and register later, please',
    to_buy_tickets: 'to buy tickets;',
    copy_ticket_code:
      "Copy the registration code we'll send you to your email and register with it.",
    ticket_code_invalid: 'Registration code is invalid or has already been used',
    ticket_code_label: 'Registration code',
    promo_code_label: 'Promo code',
    ticket_code_placeholder: 'Insert your registration code',
    promo_code_placeholder: 'Insert your promo code',
    code_message: 'Use this field to redeem ticket codes, exhibitor codes or promo codes',
    promo_code_message: 'Use this field to redeem promo codes',
    use_ticket_code: 'Use a ticket code',
    promo_code_invalid: 'Promo code is invalid or has already been used',
    non_applicable_promo_code:
      'Your promo-code is not applicable to the selected ticket type. Please choose another.',
    promo_code_success: 'Promo code applied successfuly! </br> Please select a ticket.',
  },
  addons: {
    subtitle: 'Add additional sessions and/or items',
  },
  managedFields: {
    title: 'Registration information',
  },
  review: {
    title: 'Review information',
    name: 'Name',
    proceed_to_payment: 'Continue',
    register_someone_else: 'Add another person',
  },
  checkout: {
    vat_label: 'VAT',
    country_label: 'Country',
    country_default: 'Select your country',
    city_label: 'City',
    address_label: 'Address',
    zip_code: 'Zip code',
    payment_method: 'Payment method',
    payment_method_card_label: 'Credit or debit card',
    payment_processed: 'Payment processed.',
    check_your_phone: 'Please check your phone.',
    payment_failed: 'Payment failed. Please try again.',
    payment_declined: 'The phone number provided does not have an active MB WAY service.',
    payment_details_sent: 'We sent the payment details to your email.',
    mbref_payment_message: "We'll send an MB reference to your email.",
    credit_card_label: 'Credit or debit card',
    billing_information: 'Billing information',
    bank_transfer_label: 'Bank transfer',
    bank_transfer_info: `
    <p>Bank transfer payment</p>
    <ul>
      <li>
        After submitting this form, please verify your inbox to get the payment
        information;
      </li>
      <li>Transfer the payment to the account displayed on the email;</li>
      <li>
        Send a proof of payment to the email address displayed in the email and
        include the payment ID on the response
      </li>
    </ul>
    `,
    postal_code_error: 'Postal code with the wrong format.',
  },
  completed: {
    title: 'Thank you for your registration!',
    title_store: 'Thank you for your order!',
    subtitle: "Please check your inbox, we've sent you a confirmation email.",
    subtitle_store: "Please check your inbox, we've sent you an email containing the tickets.",
    manage: 'Manage',
  },
  back: 'Back',
  next: 'Next',
  submit: 'Submit',
  not_allowed_title: 'Your account is pending aproval',
  not_allowed_subtitle: "Please wait for the event manager's approval",
  registration_slow: 'Registration is taking longer than usual. Please wait.',
  order_summary: 'Order summary',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Talk to fellow visitors',
  chat: 'Chat',
  profile: 'Profile',
  signup_title: 'Do you wish to meet other visitors?',
  signup_message:
    'You’re about to join the networking area. Your information will be available to other visitors.',
};

const sessions: IVisitorSessions = {
  subtitle: "Check your registration for the event's sessions here",
  anonymous_subtitle: "Check here and register for the event's sessions",
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
  all_sessions: 'All sessions',
  join_in_15: 'This session is not currently ongoing',
  need_help: 'Need help?',
  refresh: 'Refresh',
  troubleshoot: 'Troubleshoot',
  help_title: 'Having issues?',
  help_content: `
    <p>
      If you have issues with chat or audio please make sure you're not using a restricted proxy
      connection or have a VPN enabled at this time. <br />
    </p>
    <p>
      Check
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >this guide </a
      >to help troubleshoot any issue unresolved by updating your network settings.
    </p>
    <p>
      The following network ports must be open on your firewall to allow for server traffic:
    </p>
    <ul>
     <li>80 TCP – for SSL certificate verification with Let's Encrypt. </li>
     <li>443 TCP – for general access by the video call provider.</li>
     <li>10000 UDP - for network video and audio communications.</li>
     <li>TCP/4443</li>
    </ul>
  `,
  select_stage: 'Select a stage',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessions in which you are registered',
};

const profile: IVisitorProfile = {
  visitor_information: 'Visitor information',
};

const products: IVisitorProducts = {
  title: 'Offers',
  subtitle: 'Browse and interact with exhibitor offers',
  products_same_brand: 'Offers from the same brand',
  select_category: 'Select a category',
};

const chat: IVisitorChatMessages = {
  rooms_empty: 'No conversation',
  room_empty: 'No conversations selected',
  new_messages: 'New mensages',
  message_deleted: 'Deleted message',
  messages_empty: 'No messages',
  conversation_started: 'Conversation started on: ',
  type_message: 'Type message',
  search: 'Search',
  is_online: 'is online',
  last_seen: 'last seen',
  is_typing: 'is typing...',
  cancel_select_message: 'Cancel selection',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
  store,
  chat,
};
