import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  featured_products: 'Featured job offers',
};

const sidebar: Partial<IVisitorSidebar> = {
  // Leave empty until needed
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  subtitle: 'See exhibitors and check their job offers',
  interacted_exhibitors: 'Exhibitors I applied to',
  not_interacted_exhibitors: 'Exhibitors I didn’t apply to',
  contact_request: 'Leave my resume',
  company_info: 'Exhibitor information',
  job_offers: 'Job offers',
  apply_to_job_offer: 'Apply',
  job_offer_info: 'Information about the job offer',
  cover_letter: 'Cover letter',
  cover_letter_text: 'Tell us why you’re the right person for this position',
  your_info_will_be_shared: 'Your profile will be shared with the company',
  submit_my_application: 'Submit',
  successfully_submited: 'Application submitted successfuly',
  already_applied: "You've already applied to this position",
  schedule_interview: 'Book your spot',
  select_time_slot: 'Select a time slot to join',
  all_visitors: 'All candidates',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Job offers',
  subtitle: 'Browse and apply to job offers',
  products_same_brand: 'Job offers from the same exhibitor',
  select_category: 'Select a category',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
