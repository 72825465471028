import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorExhibitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Profil',
  networking: 'Networking',
  networking_description: 'Andere Besucher treffen',
  sessions: 'Kalender',
  sessions_description: 'Anzahl der Sitzungen im Event-Kalender:',
  sessions_btn: 'Event-Kalender ansehen',
  name: 'Name des Besuchers:',
  edit: 'Bearbeiten',
  exhibitors: 'Aussteller',
  nrOfInteractions: 'Anzahl der Male, die ich meine Kontakte geteilt habe:',
  seeAll: 'Aussteller ansehen',
  download: 'Herunterladen',
  join_networking: 'Dem Networking-Bereich beitreten',
  event_finished: 'Event ist vorbei',
  event_not_start: 'Event hat noch nicht begonnen',
  featured_products: 'Empfohlene Produkte',
  featured_sessions: 'Empfohlene Sitzungen',
  featured_exhibitors: 'Vorgestellte Aussteller',
  upcoming_exhibitors: 'Nächste Sitzungen',
};

const sidebar: IVisitorSidebar = {
  title: 'Besuch',
  home: 'Home',
  exhibitors: 'Aussteller',
  settings: 'Profil',
  sessions: 'Kalender',
  exhibitor_sessions: 'Mein Kalender',
  event_sessions: 'Ereignis-Sitzungen',
  myCalendar: 'Mein Kalender',
  eventCalendar: 'Veranstaltungskalender',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Besuchereinstellungen',
  basicInfo: 'Basisinformation',
  save: 'Speichern',
  additionalInfo: 'Zusätzliche Information',
  formError: 'Keine Informationen abgerufen, Formular nicht verfügbar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Aussteller',
  subtitle: 'Aussteller ansehen und ihre Angebote überprüfen',
  download: 'Herunterladen',
  noExhibitors: 'Keine Aussteller anzeigbar',
  exhibitor_information: 'Ausstellerinformation',
  contact_success: 'Ihre Information wurde erfolgreich geteilt!',
  all_exhibitors: 'Alle Aussteller',
  interacted_exhibitors: 'Aussteller, mit denen ich interagiert habe',
  not_interacted_exhibitors: 'Aussteller, mit denen ich nicht interagiert habe',
  filter_exhibitors: 'Aussteller filtern',
  contact_request: 'Kontakt hinterlassen',
  company_info: 'Ausstellerinformation',
  job_offers: 'Angebote',
  apply_to_job_offer: 'Ich bin interessiert',
  job_offer_info: 'Information zu dem Angebot',
  cover_letter: 'Warum sind Sie interessiert?',
  cover_letter_text:
    'Lassen Sie den Aussteller wissen, warum Sie interessiert sind und/oder fragen Sie ihn etwas über das Angebot',
  your_info_will_be_shared: 'Ihre Information wird mit dem Aussteller geteilt',
  submit_my_application: 'Einreichen',
  successfully_submited: 'Erfolgreich eingereicht ',
  already_applied: 'Sie haben bereits Ihr Interesse an dem Angebot bekundet.',
  schedule_interview: 'Reservieren Sie sich Ihren Platz',
  select_time_slot: 'Wählen Sie einen Time-Slot um beizutreten',
  all_visitors: 'Alle Besucher',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  basicInformation: {
    title: 'Erzählen Sie uns von Ihrem Besucher.',
    createVisitor: 'Besucher erstellen',
    modal: {
      title: 'Warnung',
      subtitle: 'Wenn Sie Ihren Besucher verwalten möchten, melden Sie sich bitte zuerst an.',
      skip: 'Überspringen',
      createAccount: 'Benutzerkonto anlegen',
      login: 'Einloggen',
    },
    already_registered: 'You are already registered in this event!',
    send_login_link: 'Click here to receive the confirmation email.',
  },
  tickets: {
    title: 'Eintrittskarten',
    subtitle: 'Tickets kaufen oder einen Ticket-Code verwenden',
    message_head:
      'Wenn Sie Tickets in großen Mengen kaufen und sich später registrieren möchten, wenden Sie sich bitte an',
    to_buy_tickets: 'tickets kaufen;',
    copy_ticket_code:
      'Kopieren Sie den Ticketcode, den wir Ihnen an Ihre E-Mail-Adresse senden, und registrieren Sie sich damit.',
    ticket_code_invalid: 'Der Ticketcode ist ungültig oder wurde bereits verwendet',
    ticket_code_label: 'Registrierungscode',
    ticket_code_placeholder: 'Geben Sie Ihren Ticketcode ein',
    use_ticket_code: 'Verwenden Sie einen Ticket-Code',
  },
  managedFields: {
    title: 'Zusätzliche Information',
  },
  review: {
    title: 'Überprüfen Sie die Informationen',
    name: 'Name',
    proceed_to_payment: 'Mit der Zahlung fortfahren',
    register_someone_else: 'Jemand anderen registrieren',
  },
  checkout: {
    vat_label: 'Mehrwertsteuer',
    country_label: 'Land',
    country_default: 'Wählen Sie Ihr Land',
    city_label: 'Stadt',
    address_label: 'Anschrift',
    zip_code: 'Postleitzahl',
    payment_method: 'Zahlungsmethode',
    payment_method_card_label: 'Kredit- oder Debitkarte',
    payment_processed: 'Zahlung verarbeitet.',
    check_your_phone: 'Bitte überprüfen Sie Ihr Telefon.',
    payment_failed: 'Zahlung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    payment_details_sent: 'Wir haben die Zahlungsdetails an Ihre E-Mail gesendet.',
    mbref_payment_message: 'Wir senden Ihnen eine MB-Referenz an Ihre E-Mail.',
  },
  completed: {
    title: 'Vielen Dank für Ihre Anmeldung!',
    subtitle:
      'Bitte prüfen Sie Ihren Posteingang, wir haben Ihnen eine Bestätigungs-E-Mail geschickt.',
    manage: 'Verwalten',
  },
  back: 'Zurück',
  next: 'Nächste',
  submit: 'Einreichen',
  not_allowed_title: 'Die Genehmigung Ihres Kontos steht noch aus',
  not_allowed_subtitle: 'Bitte warten Sie auf die Genehmigung des Eventmanagers',
  registration_slow: 'Die Registrierung dauert länger als üblich. Bitte warten Sie.',
  order_summary: 'Resumo da encomenda',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Mit andere Besuchern sprechen',
  chat: 'Chat',
  profile: 'Profil',
  signup_title: 'Möchten Sie andere Besucher treffen?',
  signup_message:
    'Sie sind dabei, dem Networking-Bereich beizutreten Ihre Information wird für andere Besucher verfügbar sein.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Überprüfen Sie hier Ihre Anmeldung für die Sitzungen der Veranstaltung',
  anonymous_subtitle: 'Klicken Sie hier und melden Sie sich für die Sitzungen der Veranstaltung an',
  event_sessions: 'Ereignissitzungen',
  exhibitor_sessions: 'Ausstellersitzungen',
  all_sessions: 'Alle Sitzungen',
  join_in_15: 'Sie können 15 Minuten vor Sitzungsbeginn beitreten',
  need_help: 'Brauchen Sie Hilfe?',
  refresh: 'Refresh',
  troubleshoot: 'Fehlerbehebung',
  help_title: 'Haben Sie Probleme?',
  help_content: `
    <p>
    Wenn Sie Probleme mit Chat oder Audio haben, stellen Sie bitte sicher, dass Sie keine eingeschränkte Proxy
    Verbindung verwenden oder ein VPN aktiviert haben. <br />
    </p>
    <p>
    Siehe
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >diesen Leitfaden</a
      > um bei der Behebung von Problemen zu helfen, die durch die Aktualisierung der Netzwerkeinstellungen nicht behoben werden können.
    </p>
    <p>
    Die folgenden Netzwerkports müssen in Ihrer Firewall geöffnet sein, um den Serververkehr zu ermöglichen:
    </p>
    <ul>
     <li>80 TCP – für die Überprüfung von SSL-Zertifikaten mit Let's Encrypt. </li>
     <li>443 TCP – für den allgemeinen Zugriff durch den Anbieter von Videoanrufen</li>
     <li>10000 UDP - für die Video- und Audiokommunikation im Netzwerk.</li>
     <li>TCP/4443</li>
    </ul>
    `,
  select_stage: 'Wählen Sie eine Stufe',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sitzungen, für die Sie angemeldet sind',
};

const profile: IVisitorProfile = {
  visitor_information: 'Besucherinformation',
};

const products: IVisitorProducts = {
  title: 'Produkte',
  subtitle: 'Durchsuchen und interagieren Sie mit Ausstellerprodukten',
  products_same_brand: 'Offers from the same brand',
  select_category: 'Wählen Sie eine Kategorie',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
};
