import {
  IManagerLegacyNavTranslations,
  IEventManagerHomeTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerProductsTranslations,
  IEventManagerSessionsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerEventsTranslations,
  IManagerTranslations,
} from '@/translations/default/managers';

const legacy_nav: IManagerLegacyNavTranslations = {
  event_details: 'Detalhes do evento',
  event_information: 'Informações do evento',
  media_channels: 'Canais de media',
  team: 'Equipa',
  networking: 'Networking',
  trends: 'Tendências',
  visitor_types: 'Tipos de visitantes',
  packages: 'Pacotes',
  exhibitors: 'Expositores',
  exhibitor_form: 'Formulário de expositores',
  exhibitor_list: 'Lista de expositores',
  exhibitor_cards: 'Cartões de expositor',
  exhibitor_sessions: 'Sessões de expositores',
  exhibitor_categories: 'Categorias de expositores',
  product_form: 'Formulário do produto',
  product_list: 'Lista de produtos',
  product_categories: 'Categorias de produtos',
  interactions: 'Interações',
  exhibitor_tickets: 'Bilhetes de expositor',
  exhibitor_packages: 'Pacotes de expositor',
  visitors: 'Visitantes',
  tickets: 'Bilhetes',
  visitor_form: 'Formulário de visitante',
  session_areas: 'Sessão áreas',
  labels: 'Rótulos',
  consumptions: 'Consumos',
  visitors_list: 'Lista de visitantes',
};

const home: IEventManagerHomeTranslations = {
  title: 'Hello',
  subtitle: 'Welcome to the beamian event management app',
  visitor_registration: 'Visitor registration',
  exhibitor_registration: 'Exhibitor registration',
  general_information_title: 'Your performance indicators',
  general_information_subtitle: 'How visitors are interacting with you',
  networking_subtitle: 'Visitors enrolled in networking',
  manage_networking_space: 'Manage networking space',
  products_subtitle: 'Amount of products created',
};

const exhibitors: IEventManagerExhibitorsTranslations = {
  ticket_invites: 'Atribuição de bilhetes',
  allowance_label: 'Limite de atribuições',
  allowance_message: 'Número de utilizações permitidas de cada bilhete',
  ticket_code_label: 'Código do bilhete',
  ticket_code_message: 'Os expositores podem partilhar este código para utilizar a sua mesada',
  ticket_type_default: 'Seleccione o tipo de bilhete',
  ticket_type_label: 'Tipo de bilhete',
  products_limit_label: 'Limites do produto',
  products_limit_message: 'Número máximo de produtos que um expositor pode criar',
  sessions_limit_label: 'Limites de sessão',
  sessions_limit_message: 'Número máximo de sessões que um expositor pode criar',
  exhibitor_tier_label: 'Tier',
  exhibitor_tier_default: 'Seleccione este tier de expositor',
  exhibitor_tier_message: 'Quanto mais alto o tier, mais proeminentes aparecem no evento',
  exhibitor_tier_ds: 'Sem tier',
  exhibitor_tier: 'Tier',
  exhibitor_qrcode_reader_ds: 'Habilitar interações por QR Code ou NFC',
  exhibitor_qrcode_reader_message: 'Permitir aos expositores escanear códigos QR',
  empty_list_title: 'Inscrições de expositores',
  empty_list_subtitle: 'Deixe os expositores saberem onde se inscrever',
};

const products: IEventManagerProductsTranslations = {
  empty_list_form_title: 'Forma',
  empty_list_form_subtitle: 'Configurar um formulário de produto',
  empty_list_categories_title: 'Categorias',
  empty_list_categories_subtitle: 'Adicionar categorias de produtos',
  empty_list_product_title: 'Produto',
  empty_list_product_subtitle: 'Criar um novo produto',
  title: 'Produtos disponíveis',
  subtitle: 'Número de produtos criados',
  empty_list_title: 'Vamos começar a criar coisas!',
  empty_list_subtitle: 'Construa o seu catálogo',
};

const sessions: IEventManagerSessionsTranslations = {
  empty_list_title: 'Vamos começar a criar coisas!',
  empty_list_subtitle: 'Preencha o seu calendário',
};

const exports: IEventManagerExportsTranslations = {
  empty_list_title: 'Exportar contactos',
  empty_list_subtitle: 'Obtenha os seus contactos!',
};

const events: IEventManagerEventsTranslations = {
  title: 'Bem vindo à Beamian',
  subtitle: 'Por favor escolha um evento',
  rolesTitle: 'Bem vindo à beamian',
  rolesSubtitle: 'O que precisa fazer?',
  registerVisitor: 'Registar visitante',
  registerVisitorDescription: 'Registe-se neste evento como visitante.',
  createExhibitor: 'Registar expositor',
  createExhibitorDescription: 'Registe o seu expositor neste evento.',
  manageExhibitor: 'Gerir expositor',
  manageExhibitorDescription: 'Editar informação de expositor, ver interações.',
  manageVisitor: 'Gerir visitante',
  manageVisitorDescription: 'Editar informação de visitante, ver interações.',
  manageEvent: 'Gerir evento',
  manageEventDescription: 'Aceder a configuraçoes e informação do evento.',
  event_settings: 'Definições do evento',
  name: 'Nome do evento',
  email: 'Email do gestor de evento',
  new: 'Novo evento',
  edit: 'Editar evento',
  delete: 'Apagar',
  create: 'Criar evento',
  send_registration_invite: 'Enviar convite de registo',
  assign: 'Atribuir',
  delete_manager: 'Remover gestor de evento',
  no_managers_assigned: 'Este evento não tem gestores atribuidos',
  countdown_date: 'Data da contagem regressiva',
  start_date: 'Data de início',
  end_date: 'Data de fim',
  capacity: 'Capacidade',
  used: 'Usado',
  available: 'Disponível',
  location: 'Localização',
  location_placeholder: 'Digite a localização do evento',
  is_published: 'Está publicado',
  base_cost: 'Custo base',
  max_capacity: 'Capacidade máxima',
  assign_manager: 'Atribuir gestor de evento (selecione apenas 1 evento)',
  name_placeholder: 'Digite o nome do evento',
  manager: 'Gestor de evento',
  select_manager: 'Selecione gestor de evento',
  timezone: 'Fuso horário',
  europe: 'Europa',
  london: 'Londres',
  lisbon: 'Lisboa',
  madrid: 'Madrid',
  berlin: 'Berlim',
  default_language: 'Idioma pre-definido',
  portuguese: 'Portugues',
  spanish: 'Espanhol',
  english: 'Inglês',
  german: 'Alemão',
  french: 'Francês',
  description: 'Descrição',
  description_placeholder: 'Digite uma descrição para o evento',
  image: 'Imagem',
  image_placeholder: 'Clique para fazer upload de imagem',
  background_image: 'Imagem de fundo',
  background_image_placeholder: 'Clique para fazer upload de imagem',
  created_success: 'Evento criado.',
  managers: 'Gestores de evento',
  manager_email_placeholder: 'Digite o email do utilizador',
  manager_added_success: 'Gestor de evento adicionado.',
  manager_deleted_success: 'Gestor de evento eliminado.',
  manager_user_not_exists:
    'Utilizador não existe, certifique-se que escreveu o email corretamente ou envie um convite de registo.',
  manager_setup: 'Definir nome e password',
  manager_user_password: 'Palavra passe',
  manager_user_name_placeholder: 'Digite nome do utilizador',
  manager_user_password_placeholder: 'Digite a palavra chave do utilizador',
  setup_booklet: 'Definir booklet',
  booklet: 'booklet',
  title_placeholder: 'Digite o título do livreto',
  cover: 'Capa',
  cover_placeholder: 'Carregar imagem da capa do livreto',
  configure_booklet: 'Se você deseja enviar um livreto quando o evento terminar, configure-o.',
  no_fields_selected: 'Nenhum campo selecionado.',
  drag_drop_fields: 'Arraste e solte campos para definir a ordem.',
  fields: 'Campos',
  create_booklet_success: 'Booklet criado',
  create_booklet_error: 'Criação de booklet falhou',
  edit_booklet_success: 'Booklet editado',
  edit_booklet_error: 'Edição de booklet falhou',
  no_available_fields: 'Sem campos disponíveis',
  empty_space: 'Espaço vazio',
  live_interactions_email: 'Email de convite das interações live',
  export_registration_intents: 'Logs de status dos registos',
  no_registration_intents: 'Evento não tem status de registos.',
};

const managers: Partial<IManagerTranslations> = {
  legacy_nav,
  home,
  exhibitors,
  products,
  events,
  sessions,
  exports,
};

export default managers;
