import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  // Leave empty until needed
};

const sidebar: Partial<IVisitorSidebar> = {
  // Leave empty until needed
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  contact_success: 'Solicitud de contacto exitosa!',
  contact_request: 'Intercambiar contactos',
  job_offers: 'Productos',
  apply_to_job_offer: 'Estoy interesado',
  job_offer_info: 'Información del producto',
  cover_letter_text:
    'Dinos por qué te interesa este producto pregúntanos cualquier duda que puedas tener',
  your_info_will_be_shared: 'Tu información de registro será compartida con la organización',
  submit_my_application: 'Mostrar interés',
  successfully_submited: 'Su solicitud fue exitosa',
  already_applied: 'Ya has interactuado con este producto/servicio',
  schedule_interview: 'Programe su entrevista',
  select_time_slot: 'Selecciona una franja horaria para programar una entrevista con nosotros',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Productos',
  subtitle: 'Navegar e interactuar con los productos de los expositores',
  products_same_brand: 'Productos del mismo expositor',
  select_category: 'Selecciona una categoría',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
