import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  featured_products: 'Ofertas de trabajo destacadas',
};

const sidebar: Partial<IVisitorSidebar> = {
  // Leave empty until needed
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  interacted_exhibitors: 'Empresas a las que me presenté',
  not_interacted_exhibitors: 'Empresas a las que no me presenté',
  contact_request: 'Aplicación espontánea',
  company_info: 'Información de la empresa',
  job_offers: 'Ofertas de trabajo',
  apply_to_job_offer: 'Aplicar',
  job_offer_info: 'Información de la oferta de empleo',
  cover_letter: 'Carta de movitación',
  cover_letter_text: 'Dinos por qué eres la persona adecuada para este trabajo',
  your_info_will_be_shared: 'Tu información de registro será compartida con la organización',
  submit_my_application: 'Enviar la solicitud',
  successfully_submited: 'Su solicitud fue exitosa',
  already_applied: 'Ya ha hecho una solicitud para esta oferta de trabajo.',
  schedule_interview: 'Reserve su plaza',
  select_time_slot: 'Seleccione una franja horaria para unirse',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Ofertas de trabajo',
  subtitle: 'Buscar ofertas de trabajo y postularse',
  products_same_brand: 'Ofertas de trabajo de la misma empresa',
  select_category: 'Selecciona una categoría',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
