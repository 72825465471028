import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  featured_products: 'Ofertas de emprego destacadas',
};

const sidebar: Partial<IVisitorSidebar> = {
  title: 'Candidatos',
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  subtitle: 'Ver expositores e consultar ofertas de emprego',
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  interacted_exhibitors: 'Expositores aos quais me candidatei',
  not_interacted_exhibitors: 'Expositores aos quais não me candidatei',
  filter_exhibitors: 'Filtrar expositores',
  contact_request: 'Candidatura espontânea',
  company_info: 'Informação do expositor',
  job_offers: 'Ofertas de emprego',
  apply_to_job_offer: 'Candidatura',
  job_offer_info: 'Informação sobre a oferta de emprego',
  cover_letter: 'Carta de apresentação',
  cover_letter_text: 'Diga-nos porque é que é a pessoa certa para este lugar',
  your_info_will_be_shared: 'O seu perfil será partilhado com o expositor',
  submit_my_application: 'Submeter',
  successfully_submited: 'Candidatura efetuada com sucesso',
  already_applied: 'Já se candidatou a esta oferta',
  all_visitors: 'Todos os candidatos',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Ofertas de emprego',
  subtitle: 'Procurar e responder a ofertas de emprego',
  products_same_brand: 'Ofertas de emprego do mesmo expositor',
  select_category: 'Selecione uma categoria',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
