import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  // Leave empty until needed
};

const sidebar: Partial<IVisitorSidebar> = {
  // Leave empty until needed
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  interacted_exhibitors: 'Aussteller, mit denen ich interagiert habe',
  not_interacted_exhibitors: 'Aussteller, mit denen ich nicht interagiert habe',
  contact_request: 'Kontakt hinterlassen',
  company_info: 'Ausstellerinformation',
  job_offers: 'Angebote',
  apply_to_job_offer: 'Ich bin interessiert',
  job_offer_info: 'Information zu dem Angebot',
  cover_letter: 'Warum sind Sie interessiert?',
  cover_letter_text:
    'Lassen Sie den Aussteller wissen, warum Sie interessiert sind und/oder fragen Sie ihn etwas über das Angebot',
  your_info_will_be_shared: 'Ihre Information wird mit dem Aussteller geteilt',
  submit_my_application: 'Einreichen',
  successfully_submited: 'Erfolgreich eingereicht ',
  already_applied: 'Sie haben bereits Ihr Interesse an dem Angebot bekundet.',
  schedule_interview: 'Reservieren Sie sich Ihren Platz',
  select_time_slot: 'Wählen Sie einen Time-Slot um beizutreten',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Produkte',
  subtitle: 'Durchsuchen und interagieren Sie mit Ausstellerprodukten',
  products_same_brand: 'Offers from the same brand',
  select_category: 'Wählen Sie eine Kategorie',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
