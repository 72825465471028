import {
  IEventManagerAddonsTranslations,
  IEventManagerCheckInTranslations,
  IEventManagerEventsTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerHomeTranslations,
  IEventManagerProductsTranslations,
  IEventManagerPromoCodesTranslations,
  IEventManagerSessionsTranslations,
  IManagerLegacyNavTranslations,
  IManagerTranslations,
  IEventManagerTicketsTranslations,
} from '.';

const promo_codes: IEventManagerPromoCodesTranslations = {
  discount_value: 'Discount value',
  discount_type: 'Discount type',
  discount_type_percentage: 'Percentage',
  discount_type_value: 'Value',
  promo_code_type: 'Promo-code type',
  promo_code_type_discount: 'Discount',
  promo_code_type_revealing: 'Reveal hidden tickets',
  promo_code_type_hybrid: 'Discounts hidden tickets',
};

const legacy_nav: IManagerLegacyNavTranslations = {
  event_details: 'Detalles del evento',
  event_information: 'Información del evento',
  media_channels: 'Canales de medios',
  team: 'Equipo',
  networking: 'Redes',
  trends: 'Noticias',
  visitor_types: 'Tipos de visitantes',
  packages: 'Paquetes',
  exhibitors: 'Expositores',
  exhibitor_form: 'Formulario de expositores',
  exhibitor_list: 'Lista de expositores',
  exhibitor_cards: 'Tarjetas de expositor',
  exhibitor_sessions: 'Sesiones de expositores',
  exhibitor_categories: 'Categorías de expositores',
  product_form: 'Formulario de producto',
  product_list: 'Lista de productos',
  product_categories: 'Categorías de productos',
  interactions: 'Interacciones',
  exhibitor_tickets: 'Entradas de expositor',
  exhibitor_packages: 'Paquetes de expositor',
  visitors: 'Visitantes',
  tickets: 'Entradas',
  visitor_form: 'Formulario de visitante',
  session_areas: 'Áreas de sesión',
  labels: 'Etiquetas',
  consumptions: 'Consumos',
  visitors_list: 'Lista de visitantes',
};

const home: IEventManagerHomeTranslations = {
  title: 'Hola',
  subtitle: 'Bienvenido a la aplicación de gestión de eventos beamian',
  visitor_registration: 'Registro de visitantes',
  exhibitor_registration: 'Registro de expositor',
  general_information_title: 'Información general',
  general_information_subtitle: 'Último número de visitantes, expositores e interacciones',
  networking_subtitle: 'Visitantes inscritos en networking',
  manage_networking_space: 'Administrar espacio de red',
  products_subtitle: 'Cantidad de productos creados',
};

const exhibitors: IEventManagerExhibitorsTranslations = {
  ticket_invites: 'Asignación de billetes',
  allowance_label: 'Límite de asignación',
  allowance_message: 'Número de usos permitidos de cada billete',
  ticket_code_label: 'Código del billete',
  ticket_code_message: 'Los expositores pueden compartir este código para utilizar su asignación',
  ticket_type_default: 'Seleccione el tipo de entrada',
  ticket_type_label: 'Tipo de entrada',
  products_limit_label: 'Límites de los productos',
  products_limit_message: 'Número máximo de productos que puede crear un expositor',
  sessions_limit_label: 'Límites de las sesiones',
  sessions_limit_message: 'Número máximo de sesiones que puede crear un expositor',
  exhibitor_tier_label: 'Nivel',
  exhibitor_tier_default: 'Seleccione el nivel de este expositor',
  exhibitor_tier_message: 'Cuanto más alto sea el nivel, más destacado aparecerá en el evento',
  exhibitor_tier_ds: 'Sin grada',
  exhibitor_tier: 'Nivel',
  exhibitor_qrcode_reader_ds: 'Habilitar interacciones por código QR o NFC',
  exhibitor_qrcode_reader_message: 'Permitir a los expositores escanear códigos QR',
  empty_list_title: 'Inscripción de expositores',
  empty_list_subtitle: 'Indique a los expositores dónde deben inscribirse',
};

const addons: IEventManagerAddonsTranslations = {
  subtitle: 'Gestionar complementos',
  name_placeholder: 'Introduzca el nombre de este complemento',
  description_placeholder: 'Describe este complemento',
  price_placeholder: 'Introduzca el valor en céntimos',
  price_label: 'Precio',
  image_placeholder: '5mb max, png, jpg',
  availability_start_date_label: 'Disponible desde',
  availability_end_date_label: 'Disponible hasta',
  time_limited_label: 'Tiempo limitado',
  time_limited_until_placeholder:
    'Selecciona este ajuste si quieres que tu addon sólo esté disponible durante un tiempo limitado',
  restricted_usage_period_label: 'Restringir el uso',
  restricted_usage_period_placeholder:
    'Seleccione esta configuración si desea que esto sólo se pueda utilizar durante un tiempo limitado',
  hidden_label: 'Oculto',
  max_quantity: 'Cantidad máxima',
  max_quantity_per_user_label: 'Cantidad máxima por usuario',
  min_quantity_per_user_label: 'Cantidad de entradas de grupo',
  group_ticket: 'Entradas de grupo',
  min_quantity_per_user_message: 'Una entrada de grupo debe incluir al menos 2 visitantes.',
  leave_ticket_empty:
    'Deja esta lista vacía si quieres que este complemento esté disponible para todos los billetes',
  select_ticket: 'Seleccionar billete',
  select_session: 'Seleccionar sesión',
  select_channel: 'Seleccionar canal',
  select_item: 'Seleccionar consumible',
  empty_list_title: 'No hay complementos creados todavía',
  empty_list_subtitle: '¡Comienza a crear complementos!',
  categories_message: 'Estos estarán disponibles en sus formularios de addons',
  categories_updated_message: 'Lista de categorías actualizada con éxito',
  ticket_type_restriction_label: 'Restricción por tipo de billete',
};

const products: IEventManagerProductsTranslations = {
  empty_list_form_title: 'Formulario',
  empty_list_form_subtitle: 'Configurar un formulario de producto',
  empty_list_categories_title: 'Categorías',
  empty_list_categories_subtitle: 'Añadir categorías de productos',
  empty_list_product_title: 'Producto',
  empty_list_product_subtitle: 'Crear un nuevo producto',
  title: 'Ofertas de empleo disponibles',
  subtitle: 'Número de ofertas de empleo creadas',
  empty_list_title: '¡Empecemos a crear cosas!',
  empty_list_subtitle: 'Construye tu catálogo',
};

const sessions: IEventManagerSessionsTranslations = {
  empty_list_title: '¡Empecemos a crear cosas!',
  empty_list_subtitle: 'Llena tu calendario',
};

const exports: IEventManagerExportsTranslations = {
  empty_list_title: 'Exportar contactos',
  empty_list_subtitle: '¡Exporta tus contactos!',
};

const checkin: IEventManagerCheckInTranslations = {
  subtitle: 'Revisa las entradas y salidas de visitantes de tu evento',
  empty_list_title: '¡No hay entradas todavía!',
  empty_list_subtitle: 'Vuelva más tarde y quizás tengamos algo para usted',
};

const events: IEventManagerEventsTranslations = {
  title: 'Bienvenido a beamian',
  subtitle: 'Por favor elija un evento',
  rolesTitle: 'Bienvenido a',
  rolesSubtitle: 'Qué quieres hacer?',
  registerVisitor: 'Registrar visitante',
  registerVisitorDescription: 'Regístrate o registra a alguien al evento como visitante.',
  createExhibitor: 'Registrar expositor',
  createExhibitorDescription: 'Registrar tu expositor en este evento.',
  manageVisitor: 'Gestionar visitante',
  manageVisitorDescription: 'Editar información del visitante, ver interacciones.',
  manageExhibitor: 'Gestionar expositor',
  manageExhibitorDescription: 'Editar información del expositor, ver interacciones.',
  manageEvent: 'Gestionar evento',
  manageEventDescription: 'Acceder a la configuración del evento, información y contenido.',
  event_settings: 'Configuración del evento',
  new: 'New event',
  edit: 'Edit event',
  create: 'Create event',
  send_registration_invite: 'Send registration invite',
  email: 'Email',
  name: 'Name',
  name_placeholder: 'Enter event name',
  assign: 'Assign',
  delete_manager: 'Delete event manager',
  no_managers_assigned: 'This event has no event managers assigned',
  countdown_date: 'Fecha de cuenta regresiva',
  start_date: 'Start date',
  end_date: 'End date',
  capacity: 'Capacity',
  used: 'Usado',
  available: 'Disponibles',
  location: 'Location',
  location_placeholder: 'Enter event location',
  is_published: 'Is published',
  base_cost: 'Base cost',
  max_capacity: 'Max capacity',
  delete: 'Delete event',
  assign_manager: 'Assign event manager (select 1 event only)',
  manager: 'Event manager',
  select_manager: 'Select event manager',
  timezone: 'Timezone',
  europe: 'Europe',
  lisbon: 'Lisbon',
  london: 'London',
  madrid: 'Madrid',
  berlin: 'Berlin',
  default_language: 'Default language',
  portuguese: 'Portuguese',
  spanish: 'Spanish',
  english: 'English',
  german: 'German',
  french: 'French',
  description: 'Description',
  description_placeholder: 'Enter an event description',
  image: 'Imagen',
  image_placeholder: 'Suba una imagene',
  background_image: 'Background image',
  background_image_placeholder: 'Suba una imagen',
  created_success: 'Event created successfuly',
  manager_email_placeholder: 'Enter user email',
  managers: 'Event managers',
  manager_added_success: 'Event manager added successfuly',
  manager_deleted_success: 'Event manager deleted successfuly',
  manager_user_not_exists:
    'User does not exist. Make sure you typed the email correctly or send a registration invitation to this email.',
  manager_setup: 'Choose name and password',
  manager_user_password: 'Password',
  manager_user_name_placeholder: 'Enter user name',
  manager_user_password_placeholder: 'Enter user password',
  setup_booklet: 'Set up booklet',
  booklet: 'Booklet',
  configure_booklet: 'If you want to send a booklet when the event ends please configure it.',
  no_fields_selected: 'No fields selected.',
  drag_drop_fields: 'Drag and drop fields to set order.',
  title_placeholder: 'Enter booklet title',
  cover: 'Cover',
  cover_placeholder: 'Upload booklet cover image',
  fields: 'Fields',
  create_booklet_success: 'Booklet created',
  create_booklet_error: 'Booklet creation failed',
  edit_booklet_success: 'Booklet edited',
  edit_booklet_error: 'Booklet editing failed',
  no_available_fields: 'No available fields',
  empty_space: 'Empty space',
  live_interactions_email: 'Live interactions email invitation',
  export_registration_intents: 'Logs del estado de registro',
  no_registration_intents: 'El evento no tiene estado de registro',
};

const tickets: IEventManagerTicketsTranslations = {
  subtitle: 'Gestiona tus entradas',
  amount: 'Cantidad',
  canceled: 'Cancelado',
};

const managers: IManagerTranslations = {
  legacy_nav,
  home,
  exhibitors,
  addons,
  products,
  promo_codes,
  events,
  sessions,
  exports,
  checkin,
  tickets,
};

export default managers;
