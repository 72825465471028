import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  featured_products: 'Featured products',
};

const sidebar: Partial<IVisitorSidebar> = {
  // Leave empty until needed
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  subtitle: 'See exhibitors and check their products',
  job_offers: 'Products',
  apply_to_job_offer: "I'm interested",
  job_offer_info: 'Information about the product',
  cover_letter: 'Why are you interested?',
  cover_letter_text:
    "Let the exhibitor know why you're interested and/or ask him a question about the product",
  submit_my_application: 'Submit',
  successfully_submited: 'Successfully submitted ',
  already_applied: 'You have already shared your interest in this offer.',
  schedule_interview: 'Reserve your spot',
  select_time_slot: 'Choose a time slot to join',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Products',
  subtitle: 'Browse and show interest in products',
  products_same_brand: 'Products from the same exhibitor',
  select_category: 'Select a category',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
