import {
  IVisitorOnboardingTranslations,
  IVisitorExhibitorTranslations,
  IVisitorSettingsTranslations,
  IVisitorSidebar,
  IVisitorsDashboardTranslations,
  IVisitorNetworkingTranslations,
  IVisitorExhibitorSessions,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
  IVisitorStore,
  IVisitorChatMessages,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Profil',
  networking: 'Networking',
  networking_description: 'Andere Besucher treffen',
  sessions: 'Kalender',
  sessions_description: 'Anzahl der Sitzungen im Event-Kalender:',
  sessions_btn: 'Event-Kalender ansehen',
  name: 'Name des Besuchers:',
  edit: 'Bearbeiten',
  exhibitors: 'Aussteller',
  nrOfInteractions: 'Anzahl der Male, die ich meine Kontakte geteilt habe:',
  seeAll: 'Aussteller ansehen',
  download: 'Herunterladen',
  join_networking: 'Dem Networking-Bereich beitreten',
  event_finished: 'Event ist vorbei',
  event_not_start: 'Event hat noch nicht begonnen',
  featured_products: 'Empfohlene Produkte',
  featured_sessions: 'Empfohlene Sitzungen',
  featured_exhibitors: 'Vorgestellte Aussteller',
  upcoming_exhibitors: 'Nächste Sitzungen',
};

const sidebar: IVisitorSidebar = {
  title: 'Besuch',
  home: 'Home',
  exhibitors: 'Aussteller',
  settings: 'Profil',
  sessions: 'Kalender',
  exhibitor_sessions: 'Mein Kalender',
  event_sessions: 'Ereignis-Sitzungen',
  myCalendar: 'Mein Kalender',
  eventCalendar: 'Veranstaltungskalender',
};

const store: IVisitorStore = {
  title: 'Store',
  subtitle: 'Add additional sessions and/or items',
  add_to_cart: 'Add to cart',
  other_addons: 'Other addons',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Besuchereinstellungen',
  basicInfo: 'Basisinformation',
  save: 'Speichern',
  additionalInfo: 'Zusätzliche Information',
  formError: 'Keine Informationen abgerufen, Formular nicht verfügbar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Aussteller',
  subtitle: 'Aussteller ansehen und ihre Angebote überprüfen',
  download: 'Herunterladen',
  noExhibitors: 'Keine Aussteller anzeigbar',
  exhibitor_information: 'Ausstellerinformation',
  contact_success: 'Ihre Information wurde erfolgreich geteilt!',
  all_exhibitors: 'Alle Aussteller',
  interacted_exhibitors: 'Aussteller, mit denen ich interagiert habe',
  not_interacted_exhibitors: 'Aussteller, mit denen ich nicht interagiert habe',
  filter_exhibitors: 'Aussteller filtern',
  contact_request: 'Kontakt hinterlassen',
  company_info: 'Ausstellerinformation',
  job_offers: 'Angebote',
  apply_to_job_offer: 'Ich bin interessiert',
  job_offer_info: 'Information zu dem Angebot',
  cover_letter: 'Warum sind Sie interessiert?',
  cover_letter_text:
    'Lassen Sie den Aussteller wissen, warum Sie interessiert sind und/oder fragen Sie ihn etwas über das Angebot',
  your_info_will_be_shared: 'Ihre Information wird mit dem Aussteller geteilt',
  submit_my_application: 'Einreichen',
  successfully_submited: 'Erfolgreich eingereicht ',
  already_applied: 'Sie haben bereits Ihr Interesse an dem Angebot bekundet.',
  schedule_interview: 'Reservieren Sie sich Ihren Platz',
  select_time_slot: 'Wählen Sie einen Time-Slot um beizutreten',
  all_visitors: 'Alle Besucher',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Erzählen Sie uns von Ihrem Besucher.',
    createVisitor: 'Besucher erstellen',
    select_type: 'Select registration type',
    modal: {
      title: 'Warnung',
      subtitle: 'Wenn Sie Ihren Besucher verwalten möchten, melden Sie sich bitte zuerst an.',
      skip: 'Überspringen',
      createAccount: 'Benutzerkonto anlegen',
      login: 'Einloggen',
    },
    already_registered: 'You are already registered in this event!',
    already_registered_pending: 'Für diese Veranstaltung liegt bereits eine Anmeldung für Sie vor!',
    already_registered_awaiting_payment:
      'Es gibt bereits eine Anmeldung für Sie für diese Veranstaltung, die noch auf eine Zahlung wartet!',
    pre_registered_pending: 'Sie haben sich bereits für diese Veranstaltung angemeldet!',
    send_payment_details:
      'Klicken Sie hier, um eine E-Mail mit den Zahlungsinformationen zu erhalten.',
    send_login_link: 'Click here to receive the confirmation email.',
    send_rsvp_link:
      'Klicken Sie hier, um eine E-Mail mit einem Link zur Aktivierung Ihrer Registrierung zu erhalten.',
    no_payment_info: 'Wir konnten die Informationen zu dieser Zahlung nicht finden.',
    no_pre_registration: 'Wir konnten die Informationen zu dieser Vorregistrierung nicht finden.',
    payment_info_sent:
      'Prüfen Sie Ihren Posteingang, wir haben Ihnen Informationen zum Abschluss Ihrer Zahlung geschickt!',
    rsvp_link_sent:
      'Prüfen Sie Ihren Posteingang, wir haben Ihnen Informationen zur Aktivierung Ihrer Anmeldung geschickt!',
    pre_registered_email_message:
      'Die E-Mail-Adresse kann erst geändert werden, wenn die Registrierung aktiviert wurde.',
  },
  tickets: {
    title: 'Eintrittskarten',
    subtitle: 'Tickets kaufen oder einen Ticket-Code verwenden',
    subtitle_store: 'Tickets kaufen',
    message_head:
      'Wenn Sie Tickets in großen Mengen kaufen und sich später registrieren möchten, wenden Sie sich bitte an',
    to_buy_tickets: 'tickets kaufen;',
    copy_ticket_code:
      'Kopieren Sie den Ticketcode, den wir Ihnen an Ihre E-Mail-Adresse senden, und registrieren Sie sich damit.',
    ticket_code_invalid: 'Der Ticketcode ist ungültig oder wurde bereits verwendet',
    ticket_code_label: 'Registrierungscode',
    promo_code_label: 'Aktionscode',
    ticket_code_placeholder: 'Geben Sie Ihren Ticketcode ein',
    promo_code_placeholder: 'Geben Sie Ihren Aktionscode ein',
    use_ticket_code: 'Verwenden Sie einen Ticket-Code',
    code_message: 'Use this field to redeem ticket codes, exhibitor codes or promo codes',
    promo_code_message: 'Verwenden Sie dieses Feld, um Aktionscodes einzulösen',
    promo_code_invalid: 'Der Aktionscode ist ungültig oder wurde bereits verwendet',
    non_applicable_promo_code:
      'Ihr Aktionscode gilt nicht für ausgewählte Tickets. Bitte wählen Sie eine andere aus.',
    promo_code_success: 'Aktionscode erfolgreich aktiviert! <br> Bitte wählen Sie ein Ticket aus',
  },
  addons: {
    subtitle: 'Zusätzliche Artikel kaufen',
  },
  managedFields: {
    title: 'Zusätzliche Information',
  },
  review: {
    title: 'Überprüfen Sie die Informationen',
    name: 'Name',
    proceed_to_payment: 'Mit der Zahlung fortfahren',
    register_someone_else: 'Jemand anderen registrieren',
  },
  checkout: {
    vat_label: 'Mehrwertsteuer',
    country_label: 'Land',
    country_default: 'Wählen Sie Ihr Land',
    city_label: 'Stadt',
    address_label: 'Anschrift',
    zip_code: 'Postleitzahl',
    payment_method: 'Zahlungsmethode',
    payment_method_card_label: 'Kredit- oder Debitkarte',
    payment_processed: 'Zahlung verarbeitet.',
    check_your_phone: 'Bitte überprüfen Sie Ihr Telefon.',
    billing_information: 'Informationen zur Rechnungsstellung',
    payment_failed: 'Zahlung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    payment_declined: 'Die verwendete MB WAY Nummer hat keinen Dienst.',
    payment_details_sent: 'Wir haben die Zahlungsdetails an Ihre E-Mail gesendet.',
    mbref_payment_message: 'Wir senden Ihnen eine MB-Referenz an Ihre E-Mail.',
    credit_card_label: 'Credit or debit card',
    bank_transfer_label: 'Bank transfer',
    bank_transfer_info: `
    <p>Bank transfer payment</p>
    <ul>
      <li>
        After submitting this form, please verify your inbox to get the payment
        information;
      </li>
      <li>Transfer the payment to the account displayed on the email;</li>
      <li>
        Send a proof of payment to the email address displayed in the email and
        include the payment ID on
      </li>
    </ul>
    `,
    postal_code_error: 'Postleitzahl mit falschem Format.',
  },
  completed: {
    title: 'Vielen Dank für Ihre Anmeldung!',
    title_store: 'Danke für Ihren Einkauf!',
    subtitle:
      'Bitte prüfen Sie Ihren Posteingang, wir haben Ihnen eine Bestätigungs-E-Mail geschickt.',
    subtitle_store:
      'Bitte überprüfen Sie Ihren Posteingang, wir senden Ihnen eine E-Mail mit den Tickets.',
    manage: 'Verwalten',
  },
  back: 'Zurück',
  next: 'Nächste',
  submit: 'Einreichen',
  not_allowed_title: 'Die Genehmigung Ihres Kontos steht noch aus',
  not_allowed_subtitle: 'Bitte warten Sie auf die Genehmigung des Eventmanagers',
  registration_slow: 'Die Registrierung dauert länger als üblich. Bitte warten Sie.',
  order_summary: 'Resumo da encomenda',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Mit andere Besuchern sprechen',
  chat: 'Chat',
  profile: 'Profil',
  signup_title: 'Möchten Sie andere Besucher treffen?',
  signup_message:
    'Sie sind dabei, dem Networking-Bereich beizutreten Ihre Information wird für andere Besucher verfügbar sein.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Überprüfen Sie hier Ihre Anmeldung für die Sitzungen der Veranstaltung',
  anonymous_subtitle: 'Klicken Sie hier und melden Sie sich für die Sitzungen der Veranstaltung an',
  event_sessions: 'Ereignissitzungen',
  exhibitor_sessions: 'Ausstellersitzungen',
  all_sessions: 'Alle Sitzungen',
  join_in_15: 'Sie können 15 Minuten vor Sitzungsbeginn beitreten',
  need_help: 'Brauchen Sie Hilfe?',
  refresh: 'Refresh',
  troubleshoot: 'Fehlerbehebung',
  help_title: 'Haben Sie Probleme?',
  help_content: `
    <p>
    Wenn Sie Probleme mit Chat oder Audio haben, stellen Sie bitte sicher, dass Sie keine eingeschränkte Proxy
    Verbindung verwenden oder ein VPN aktiviert haben. <br />
    </p>
    <p>
    Siehe
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >diesen Leitfaden</a
      > um bei der Behebung von Problemen zu helfen, die durch die Aktualisierung der Netzwerkeinstellungen nicht behoben werden können.
    </p>
    <p>
    Die folgenden Netzwerkports müssen in Ihrer Firewall geöffnet sein, um den Serververkehr zu ermöglichen:
    </p>
    <ul>
     <li>80 TCP – für die Überprüfung von SSL-Zertifikaten mit Let's Encrypt. </li>
     <li>443 TCP – für den allgemeinen Zugriff durch den Anbieter von Videoanrufen</li>
     <li>10000 UDP - für die Video- und Audiokommunikation im Netzwerk.</li>
     <li>TCP/4443</li>
    </ul>
    `,
  select_stage: 'Wählen Sie eine Stufe',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sitzungen, für die Sie angemeldet sind',
};

const profile: IVisitorProfile = {
  visitor_information: 'Besucherinformation',
};

const products: IVisitorProducts = {
  title: 'Produkte',
  subtitle: 'Durchsuchen und interagieren Sie mit Ausstellerprodukten',
  products_same_brand: 'Offers from the same brand',
  select_category: 'Wählen Sie eine Kategorie',
};

const chat: IVisitorChatMessages = {
  rooms_empty: 'No conversation',
  room_empty: 'No conversations selected',
  new_messages: 'New mensages',
  message_deleted: 'Deleted message',
  messages_empty: 'No messages',
  conversation_started: 'Conversation started on: ',
  type_message: 'Type message',
  search: 'Search',
  is_online: 'is online',
  last_seen: 'last seen',
  is_typing: 'está typing...',
  cancel_select_message: 'Cancel selection',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
  store,
  chat,
};
