import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  // Leave empty until needed
};

const sidebar: Partial<IVisitorSidebar> = {
  title: 'Candidat',
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  subtitle: "Voir les exposants et consulter leurs offres d'emploi",
  contact_success: 'Votre CV a été partagé avec succès !',
  interacted_exhibitors: "Exposants auxquelles j'ai postulé",
  not_interacted_exhibitors: "Exposants auxquelles je n'ai pas postulé",
  contact_request: 'Laisser mon CV',
  company_info: "Informations sur l'exposant",
  job_offers: 'Produits',
  apply_to_job_offer: 'Appliquer',
  job_offer_info: "Informations sur l'offre d'emploi",
  cover_letter: 'Lettre de motivation',
  cover_letter_text: 'Dites-nous pourquoi vous êtes la bonne personne pour ce poste',
  your_info_will_be_shared: "Votre profil sera partagé avec l'entreprise",
  submit_my_application: 'Soumettre',
  successfully_submited: 'Candidature soumise avec succès',
  already_applied: 'Vous avez déjà postulé à ce poste',
  schedule_interview: 'Réservez votre place',
  select_time_slot: 'Choisissez une plage horaire pour y accéder',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Produits',
  subtitle: 'Parcourir et montrer son intérêt pour les produits',
  products_same_brand: 'Produits de la même entreprise',
  select_category: 'Sélectionner une catégorie',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
