import {
  IExhibitorsDashboardTranslations,
  IExhibitorTickets,
  IExhibitorExports,
  IExhibitorVisitors,
  IExhibitorSidebar,
  IExhibitorSettingsTranslations,
  IExhibitorProducsTranslations,
  IExhibitorInteractionsTranslations,
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorProductInteractions,
  IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  // leave empty until needed
};

const tickets: Partial<IExhibitorTickets> = {
  // leave empty until needed
};

const exports: Partial<IExhibitorExports> = {
  // leave empty until needed
};

const visitors: Partial<IExhibitorVisitors> = {
  filters: {
    registered_for_sessions: 'Registrado en las sesiones',
    interacted_with_exhibitor: 'Interactuó con el expositor',
    interacted_with_product: 'Interactuó con la oferta de empleo',
    invited_by_you: 'Invitado por usted',
    redeemed_code: 'Canjeó uno de sus códigos',
  },
};

const sidebar: Partial<IExhibitorSidebar> = {
  exhibitor_interactions: 'Aplicaciones espontáneas',
  interactions: 'Candidatos',
  products: 'Ofertas de trabajo',
  product_interactions: 'Solicitudes de empleo',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  // leave empty until needed
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Ofertas de trabajo',
  subtitle: 'Crear, editar y eliminar ofertas de trabajo',
  no_products: 'No hay productos disponibles',
  new_product: 'Nueva oferta de trabajo',
  create_new_product: 'Crear una oferta de trabajo',
  edit_product: 'Editar producto',
  interactions_exist_cannot_delete:
    'Esta oferta ya tiene candidatos, por lo que no es posible eliminar',
  form_not_ready:
    'El formulario de solicitud de oferta de empleo aún no se ha creado. Por favor, inténtelo más tarde',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Solicitudes de empleo espontáneas',
  subtitle: 'Responder a solicitudes de empleo',
  noInteractions: 'Este expositor no tiene candidatos.',
};

const onboarding: Partial<IExhibitorOnboardingTranslations> = {
  // leave empty until needed
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning:
    'Si la sesión es privada, sólo la verán los participantes invitados. Si es una sesión pública, será visible para todos los participantes.',
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: 'Aplicaciones',
  subtitle: 'Ver solicitantes de empleo, enviar invitaciones a reuniones',
  product_name: 'Oferta de trabajo',
  interaction_name: 'Nombre del candidato',
  application: 'Aplicacion de trabajo',
};

const profile: Partial<IExhibitorProfile> = {
  products: 'Ofertas de trabajo',
};

export default {
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
};
