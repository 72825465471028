import {
  IManagerLegacyNavTranslations,
  IEventManagerHomeTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerProductsTranslations,
  IEventManagerSessionsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerEventsTranslations,
  IManagerTranslations,
} from '@/translations/default/managers';

const legacy_nav: IManagerLegacyNavTranslations = {
  event_details: 'Event details',
  event_information: 'Event information',
  media_channels: 'Media channels',
  team: 'Team',
  networking: 'Networking',
  trends: 'Trends',
  visitor_types: 'Visitor types',
  packages: 'Packages',
  exhibitors: 'Exhibitors',
  exhibitor_form: 'Exhibitors form',
  exhibitor_list: 'Exhibitors list',
  exhibitor_cards: 'Exhibitor cards',
  exhibitor_sessions: 'Exhibitors sessions',
  exhibitor_categories: 'Exhibitors categories',
  product_form: 'Product form',
  product_list: 'Product list',
  product_categories: 'Product categories',
  interactions: 'Interactions',
  exhibitor_tickets: 'Exhibitor tickets',
  exhibitor_packages: 'Exhibitor packages',
  visitors: 'Visitors',
  tickets: 'Tickets',
  visitor_form: 'Visitor form',
  session_areas: 'Session areas',
  labels: 'Labels',
  consumptions: 'Consumptions',
  visitors_list: 'Visitors list',
};

const home: IEventManagerHomeTranslations = {
  title: 'Hello',
  subtitle: 'Welcome to the beamian event management app',
  visitor_registration: 'Visitor registration',
  exhibitor_registration: 'Exhibitor registration',
  general_information_title: 'Your performance indicators',
  general_information_subtitle: 'How visitors are interacting with you',
  networking_subtitle: 'Visitors enrolled in networking',
  manage_networking_space: 'Manage networking space',
  products_subtitle: 'Amount of products created',
};

const exhibitors: IEventManagerExhibitorsTranslations = {
  ticket_invites: 'Ticket invites',
  allowance_label: 'Ticket allowance',
  allowance_message: 'Number of permited uses of each ticket',
  ticket_code_label: 'Ticket code',
  ticket_code_message: 'Exhibitors can share this code to use their allowance',
  ticket_type_default: 'Select ticket type',
  ticket_type_label: 'Ticket type',
  products_limit_label: 'Product limits',
  products_limit_message: 'Max number of products an exhibitor can create',
  sessions_limit_label: 'Session limits',
  sessions_limit_message: 'Max number of sessions an exhibitor can create',
  exhibitor_tier_label: 'Tier',
  exhibitor_tier_default: "Select this exhibitor's tier",
  exhibitor_tier_message: 'The higher the tier the more prominent they appear on the event',
  exhibitor_tier_ds: 'No tier',
  exhibitor_tier: 'Tier',
  exhibitor_qrcode_reader_ds: 'Enable Interactions by QR Code or NFC',
  exhibitor_qrcode_reader_message: 'Allow exhibitors to scan QR codes',
  empty_list_title: 'Exhibitors registrations',
  empty_list_subtitle: 'Let exhibitors know where to register',
};

const sessions: IEventManagerSessionsTranslations = {
  empty_list_title: "Let's start creating things!",
  empty_list_subtitle: 'Fill up your programme',
};

const exports: IEventManagerExportsTranslations = {
  empty_list_title: 'Export contacts',
  empty_list_subtitle: 'Get your contacts!',
};

const products: IEventManagerProductsTranslations = {
  empty_list_form_title: 'Form',
  empty_list_form_subtitle: 'Set up a product form',
  empty_list_categories_title: 'Categories',
  empty_list_categories_subtitle: 'Add product categories',
  empty_list_product_title: 'Product',
  empty_list_product_subtitle: 'Create a new product',
  title: 'Offers available',
  subtitle: 'Offers created',
  empty_list_title: "Let's start creating stuff!",
  empty_list_subtitle: 'Build your catalog',
};

const events: IEventManagerEventsTranslations = {
  title: 'Welcome to Beamian',
  subtitle: 'Please choose an event',
  rolesTitle: 'Welcome to',
  rolesSubtitle: 'What do you want to do?',
  registerVisitor: 'Register visitor',
  registerVisitorDescription: 'Register yourself or someone else as a visitor on this event.',
  createExhibitor: 'Register exhibitor',
  createExhibitorDescription: 'Register your exhibitor on this event.',
  manageVisitor: 'Manage visitor',
  manageVisitorDescription: 'Edit visitor information, see interactions.',
  manageExhibitor: 'Manage exhibitor',
  manageExhibitorDescription: 'Edit exhibitor information, see interactions.',
  manageEvent: 'Manage event',
  manageEventDescription: 'Access event settings, information and content.',
  event_settings: 'Event settings',
  new: 'New event',
  edit: 'Edit event',
  create: 'Create event',
  send_registration_invite: 'Send registration invite',
  email: 'Email',
  name: 'Name',
  name_placeholder: 'Enter event name',
  assign: 'Assign',
  delete_manager: 'Delete event manager',
  no_managers_assigned: 'This event has no event managers assigned',
  countdown_date: 'Countdown Date',
  start_date: 'Start date',
  end_date: 'End date',
  capacity: 'Capacity',
  used: 'Used',
  available: 'Available',
  location: 'Location',
  location_placeholder: 'Enter event location',
  is_published: 'Is published',
  base_cost: 'Base cost',
  max_capacity: 'Max capacity',
  delete: 'Delete event',
  assign_manager: 'Assign event manager (select 1 event only)',
  manager: 'Event manager',
  select_manager: 'Select event manager',
  timezone: 'Timezone',
  europe: 'Europe',
  lisbon: 'Lisbon',
  london: 'London',
  madrid: 'Madrid',
  berlin: 'Berlin',
  default_language: 'Default language',
  portuguese: 'Portuguese',
  spanish: 'Spanish',
  english: 'English',
  german: 'German',
  french: 'French',
  description: 'Description',
  description_placeholder: 'Enter an event description',
  image: 'Image',
  image_placeholder: 'Upload an image',
  background_image: 'Background image',
  background_image_placeholder: 'Upload an image',
  created_success: 'Event created successfuly',
  manager_email_placeholder: 'Enter user email',
  managers: 'Event managers',
  manager_added_success: 'Event manager added successfuly',
  manager_deleted_success: 'Event manager deleted successfuly',
  manager_user_not_exists:
    'User does not exist. Make sure you typed the email correctly or send a registration invitation to this email.',
  manager_setup: 'Choose name and password',
  manager_user_password: 'Password',
  manager_user_name_placeholder: 'Enter user name',
  manager_user_password_placeholder: 'Enter user password',
  setup_booklet: 'Set up booklet',
  booklet: 'Booklet',
  configure_booklet: 'If you want to send a booklet when the event ends please configure it.',
  no_fields_selected: 'No fields selected.',
  drag_drop_fields: 'Drag and drop fields to set order.',
  title_placeholder: 'Enter booklet title',
  cover: 'Cover',
  cover_placeholder: 'Upload booklet cover image',
  fields: 'Fields',
  create_booklet_success: 'Booklet created',
  create_booklet_error: 'Booklet creation failed',
  edit_booklet_success: 'Booklet edited',
  edit_booklet_error: 'Booklet editing failed',
  no_available_fields: 'No available fields',
  empty_space: 'Empty space',
  live_interactions_email: 'Live interactions email invitation',
  export_registration_intents: 'Registration status logs',
  no_registration_intents: 'There are no registration status logs at this event.',
};

const managers: Partial<IManagerTranslations> = {
  legacy_nav,
  home,
  exhibitors,
  products,
  events,
  sessions,
  exports,
};

export default managers;
